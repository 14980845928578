/* You can add global styles to this file, and also import other style files */
@import '~ng-zorro-antd/ng-zorro-antd.min.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: Inter, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  font-weight: 400;
  text-align: left;
  background-color: #fff;
}

.open-access {
}

.other-access {
}

.test-instance {
  position: fixed;
  top: 0;
  background: darkred;
  color: #fff;
  font-size: 16px;
  z-index: 10000;
  padding: 7px 30px;
  border: 1px solid #fff;
  box-shadow: 3px 3px 20px RGB(0, 0, 0, 0.2);
  left: 0;
}

h1 {
  font-weight: 100;
  font-size: 2.1rem;
}

h1.page-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin: 0 0 20px;
}

.align-center {
  text-align: center;
}

#ab-view-btn {
  position: fixed;
  z-index: 999;
  bottom: 50px;
  right: 50px;
  padding: 25px;
  border-radius: 25px;
  background-color: #a2aab1;
}

#btn-menu {
  color: #233d4c;
  background: #eee;
  border: none;
}

.category-separator {
  border-top: 5px solid #f3f4f5;
  margin: 0 0 30px;
  opacity: 1;
}
.eosc-common.top {
  font-weight: 400;
}

a {
  color: #0c2bd5;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.container-xxl {
  padding: 25px 50px 75px 25px;
}

.user-profile-padding {
  padding: 80px 10px 0;
  height: 100vh;
}

.searchbar {
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  flex-grow: 2;
  margin: 0 5%;
}
.searchbar__input {
  position: relative;
  width: 100%;
}
input,
select,
textarea {
  border: 1px solid rgba(35, 61, 76, 0.5);
  border-radius: 2px;
  line-height: 1.25rem;
}
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.input-group-append {
  margin-left: -1px;
}
.svg-inline--fa.fa-w-16 {
  width: 1em;
}
.svg-inline--fa.fa-w-16 {
  width: 1em;
}
.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text {
  border-radius: 2px;
  background-color: transparent;
  outline: none;
}
.input-group-append,
.input-group-prepend {
  display: flex;
}
.searchbar__input .autocomplete-results {
  z-index: 10;
  background-color: #fff;
  position: absolute;
  border: 1px solid rgba(35, 61, 76, 0.1);
  width: 100%;
  padding: 5px 10px;
}
.searchbar__input .autocomplete-results .label {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: rgba(35, 61, 76, 0.5);
  margin-left: 0;
  margin-bottom: 0;
  padding: 0;
}
.searchbar__input .autocomplete-results .label:hover {
  cursor: unset;
  color: rgba(35, 61, 76, 0.5);
}
.searchbar__input .autocomplete-results li {
  cursor: pointer;
  padding: 2px 10px;
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.searchbar__input .autocomplete-results li span {
  color: rgba(35, 61, 76, 0.5);
}
.searchbar__input .autocomplete-results li.active,
.searchbar__input .autocomplete-results li:hover {
  color: #0c2bd5;
  background: transparent;
}
.searchbar .search-clear {
  right: 10px;
  top: 11px;
  z-index: 99;
  color: #233d4c;
  position: absolute;
}
.searchbar input {
  padding: 10px 25px 10px 10px;
  font-size: 1rem;
  margin-right: -1px;
  width: 100%;
  border-radius: 2px 0 0 2px;
  position: relative;
  z-index: 20;
  font-weight: 300;
}
.searchbar__select {
  font-size: 0.875rem;
  padding-left: 10px;
  width: 150px;
  border: 1px solid rgba(35, 61, 76, 0.5);
  position: relative;
  background: #fff;
  margin-left: -1px;
}

.searchbar__select span {
  position: absolute;
  top: 10px;
  max-width: 68%;
  text-overflow: ellipsis;
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
}
.searchbar__select select {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}
.searchbar__select:after {
  content: '';
  position: absolute;
  border-color: rgba(35, 61, 76, 0.7);
  border-style: solid;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  width: 9px;
  height: 9px;
  right: 10px;
  top: 13px;
  z-index: 1;
}
.searchbar button {
  background: transparent;
  border: 1px solid rgba(35, 61, 76, 0.5);
  padding: 0 15px;
  font-size: 1rem;
  margin-left: -1px;
}

.breadcrumbs {
  font-size: 15px;
  font-weight: 300;
  color: #a2aab1;
}
.breadcrumbs a {
  padding: 0 2px;
  font-size: 12px;
}
.breadcrumbs a:first-child {
  margin-top: 3px;
  float: left;
  padding: 0;
  text-decoration: none;
  margin-right: 4px;
  overflow: hidden;
}
.breadcrumbs a:hover {
  opacity: 0.6;
  text-decoration: none;
}
.breadcrumbs .current {
  padding: 0 2px;
  color: #233d4c;
  font-size: 12px;
}

#latest-info,
#user-activities {
  .custom-card {
    cursor: pointer;
  }
}

.card-container {
}

#logo {
  max-height: 90px;
}

#search {
  padding: 4px;
}

#search,
#btn-search {
  max-height: 45px;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.button--primary {
  color: #fff;
  font-size: 22px;
  background-color: #0c2bd5;
  background-image: linear-gradient(135deg, #05cae7, #0c2bd5);

  &:hover {
    background: #0c2bd5;
    color: #fff;
  }
}

.btn-secondary {
  color: #fff;
  background-color: #144b9e;
  border-color: #144b9e;
  padding: 6px 30px;
  border-radius: 8px;
  font-size: 22px;
}

.btn-sm-landing {
  border-radius: 8px;
  padding: 8px 24px;
  font-size: 16px;
}

.button {
  display: inline-block;
  font-weight: 400;
  width: auto;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  border: 0 solid transparent;
  padding: 0.375rem 2rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.right-section,
.service-box,
.left-section {
  .header-row {
    position: relative;

    span {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 13px;
      color: #0c2bd5;

      a {
        text-decoration: none;
      }
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 17px;
  }

  h5 {
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 14px;
  }

  .custom-card {
    border: 1px solid rgba(28, 49, 58, 0.1);
    border-radius: 5px;
    overflow: hidden;
  }

  .standard-box {
    padding: 10px 15px;
    font-size: 14px;
    line-height: 27px;
    transition: all 0.5s ease;

    &:hover {
      box-shadow: 0 0.22rem 0.9rem rgba(0, 0, 0, 0.092) !important;
    }

    p {
      line-height: 1.3;
      margin: 0 0 3px;
      font-size: 14px;
      color: #0c2bd5;

      small {
        color: #233d4c;
        font-size: 12px;
      }
    }
  }

  .big-box {
    padding: 7px 0;
    text-align: center;
    font-weight: 300;
    display: block;
    color: #233d4c;
    text-decoration: none;
    font-size: 24px;
    transition: all 0.5s ease;

    &:hover {
      box-shadow: 0 0.3rem 0.4rem rgba(0, 0, 0, 0.15) !important;
    }

    span {
      font-weight: bold;
    }
  }
}

.left-section {
  .custom-card {
    padding: 12px 12px 15px;
    transition: all 0.5s ease;

    &:hover {
      box-shadow: 0 0.22rem 0.9rem rgba(0, 0, 0, 0.075) !important;
    }

    &.no-border {
      padding: 5px 0 10px;
      border: none;

      &:hover {
        box-shadow: none !important;
      }
    }

    .card-title {
      font-size: 18px;
      line-height: 22px;
      width: 100%;
      display: block;
      margin: 0;
      text-decoration: none;
    }

    .rating-row {
      span {
        color: #ffcd00;
      }
    }

    .card-rating {
      font-size: 12px;
      display: inline;
      margin-left: 7px;
    }

    .card-description {
      font-size: 14px;
      line-height: 20px;
      width: 100%;
      display: block;
      margin: 7px 0 9px 0;
    }

    .card-organisation {
      font-size: 12px;
      line-height: 15px;
      width: 100%;
      display: block;
      margin: 0;

      a {
        text-decoration: none;
      }
    }

    .card-date {
      font-size: 12px;
      padding: 3px 12px;
      float: left;
      margin-top: 7px;
      background: #f2f2f2;
    }
  }
}

/* MAIN PAGE B */

.service-box {
  border: 1px solid rgba(28, 49, 58, 0.1);
  border-radius: 5px;
  overflow: hidden;

  h5 {
    border-bottom: 1px solid rgba(28, 49, 58, 0.15);
    padding-bottom: 5px;
  }
}

.notifications-info {
  ul {
    padding: 0 0 0 15px;
    margin: 0;

    li {
      font-size: 14px;
      line-height: 20px;
      list-style: square;
      color: #ffc107;
      margin-bottom: 10px;

      a {
        text-decoration: none;
      }
    }
  }
}
.profile svg {
  color: #e8eaeb;
  font-size: 7em;
  float: left;
  margin-right: 25px;
}
.svg-inline--fa.fa-w-16 {
  width: 1em;
}
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.display-1 {
  font-size: 2.25rem;
  font-weight: 100;
  line-height: 1.1;
}
.display-2,
.display-3 {
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1.2;
}

.btn-primary {
  background: #144b9e;
  border-radius: 8px;
  border-color: #144b9e;
  font-size: 22px;
  padding: 5px 55px;
}

.btn-sm {
  padding: 0.22rem 1.8rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.ant-drawer-header {
  color: #fff;
  background: rgb(11, 69, 216);
  background: linear-gradient(
    90deg,
    rgba(6, 181, 228, 1) 0%,
    rgba(11, 69, 216, 1) 100%
  );
  border-radius: 0;
}

.ant-drawer-title {
  color: #fff;
  font-size: 21px;
  font-weight: bold;
}

.library-title {
  text-transform: uppercase;
}

#eosc-common-main-header {
  position: relative;
  z-index: 100;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
}

#sidebarMenu {
  background: #fff;
}

.left-panel {
  height: 100vh;
  top: 0;
  width: 250px;
}

.offset-left-panel {
  margin-left: 250px;
}

.widget {
  .btn {
    margin-left: 10px;

    &:disabled {
      border: none;
      opacity: 0.5;
    }
  }
}

.widget-content {
  border: 1px solid rgba(28, 49, 58, 0.1);
  border-radius: 5px;
  padding: 15px 0 20px !important;
  overflow: hidden;
  min-height: 145px;
  box-shadow: 0 0.125rem 0.2rem rgba(0, 0, 0, 0.055);
}

.widget-content p {
  margin: 0;
  line-height: 19px;
}

.widget-icon {
  padding-bottom: 15px !important;
}

.widget-content:hover {
  box-shadow: 0 0.125rem 0.35rem rgba(0, 0, 0, 0.085);
}

@media only screen and (max-width: 992px) {
  .display-1 {
    font-size: 1.5rem;
  }
  .display-2,
  .display-3 {
    font-size: 0.8rem;
  }
  .profile svg {
    font-size: 4em;
    margin-right: 20px;
  }

  .profile-counters {
    margin: 30px 0;
  }

  h1 {
    font-weight: 100;
    font-size: 1.5rem;
  }
  .searchbar {
    display: none;
  }
  .left-section .col-12 {
    margin-bottom: 25px;
  }
  .right-section h4,
  .left-section h4 {
    font-size: 17px;
    margin-bottom: 9px;
  }
  .left-section .header-row span {
    position: relative;
    margin-bottom: 15px;
    display: block;
  }
  .ant-drawer-content-wrapper {
    width: 300px !important;
  }
  #lib-line,
  #menu-btn {
    left: 300px !important;
  }
  .ant-drawer-body {
    padding: 15px 10px;
  }
}

#topmap {
  background: linear-gradient(180deg, #edf3fa 0%, rgba(255, 255, 255, 1) 100%);
  position: relative;
  min-height: 660px;

  .stars-bg {
    background: url('assets/stars.svg') right 40px no-repeat;
    height: 645px;
  }

  .logo-eosc {
    margin-top: 130px;
  }

  .intro {
    margin-top: 25px;

    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 58px;
      letter-spacing: -0.02em;
      margin-bottom: 20px;
      color: #333;
    }

    .description {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      max-width: 575px;
      margin-bottom: 80px;
    }
  }

  .features-box {
    margin-top: 115px;
    width: 370px;
    background: #fff;
    padding: 10px 25px;
    box-shadow: 14px 8px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    .feature-line {
      font-style: normal;
      font-weight: 400;
      width: 100%;
      position: relative;
      font-size: 16px;
      line-height: 32px;
      border-bottom: 1px solid #edf3fa;
      padding: 14px 0;

      &.last {
        border: none;
      }

      .dot {
        height: 32px;
        width: 32px;
        margin-right: 25px;
        float: left;
        background-color: #abd7f1;
        border-radius: 50%;
        display: inline-block;
      }
    }
  }
}

.types {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 30px;
}

.type {
  padding: 2px 10px;
  color: #3d4db6;
  border-radius: 35px;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #3d4db6;
}

.active {
  color: white !important;
  background: #3d4db6;
}

#manage {
  padding: 0 0 130px 0;
  min-height: 640px;

  h2 {
    margin-top: 165px;
    font-weight: 600;
  }

  .image-placeholder {
    max-width: 780px;
    left: 0;

    img {
      width: 100%;
      height: auto;
    }
  }
}

ul.check-list {
  list-style: none;
  padding: 0;
  margin: 25px 0 0 0;

  li {
    list-style: none;
    background-image: url('assets/icon-check.png');
    background-repeat: no-repeat;
    background-position: left center;
    padding: 5px 0px 5px 50px;
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
    margin: 12px 0 19px;
  }
}

#community {
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, #edf3fa 100%);
  position: relative;
  padding: 85px 0 160px;

  h2 {
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #333;
  }

  .sub-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    margin: auto;
    max-width: 880px;
    text-align: center;
  }
}

.fixed-size {
  background: #fff;
  border-radius: 16px;
  padding-top: 10px;
  box-shadow: 14px 8px 16px rgb(0 0 0 / 5%);
}

#resources {
  background: url('assets/resources-bg.jpg');
  background-position: 50% 50%;
  background-attachment: fixed;
  position: relative;
  padding: 80px 0 80px;

  .pre-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: #2f80ed;
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    color: #fff;
  }

  form {
    margin-top: 75px;
    margin-right: 20%;
    display: flex;
    color: black;
    background: white;
    padding: 2px;
    border: 1px solid currentColor;
    border-radius: 8px;

    input[type='search'] {
      padding: 9px 20px;
      background: #ffffff;
      width: 100%;
      border: 0px solid #3d4db6;
      border-radius: inherit;
      margin: 0;
    }

    input[type='search']::placeholder {
      color: black;
    }

    button[type='submit']:focus,
    input[type='search']:focus {
      box-shadow: 0 0 0px 0 #1183d6;
      border-color: #1183d6;
      outline: none;
    }

    button[type='submit'] {
      text-indent: -999px;
      overflow: hidden;
      width: 40px;
      padding: 0;
      margin: 0;
      border: 1px solid transparent;
      border-radius: inherit;
      background: transparent
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
        no-repeat center;
      cursor: pointer;
      opacity: 0.7;
    }

    button[type='submit']:hover {
      opacity: 1;
    }
  }

  .right-column {
    p {
      font-size: 32px;
      line-height: 39px;
      color: #fff;
      font-weight: 600;
      padding-top: 35px;
    }
    ul.check-list {
      li {
        color: #fff;
      }
    }
  }
}

#join {
  position: relative;
  padding: 110px 0 140px;

  h3 {
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 47px;
    line-height: 50px;
    color: #333;
    margin-bottom: 60px;
  }

  .tile {
    width: 500px;
    min-height: 650px;
    background: #ffffff;
    border: 1px solid rgba(217, 222, 226, 0.3);
    box-shadow: 4px 7px 10px 3px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    position: relative;
    float: right;
    text-align: center;
    transition: all 0.5s ease;
    padding: 25px 50px 50px;

    &:hover {
      box-shadow: 10px 10px 15px 3px rgba(0, 0, 0, 0.1);
      margin-top: -6px;
    }

    &.right-tile {
      float: left;
    }

    .title {
      width: 100%;
      text-align: center;
      font-weight: 600;
      font-size: 24px;
      color: #000;
      margin: 15px 0 24px;
      line-height: 30px;
    }

    .desc {
      width: 100%;
      text-align: center;
      font-weight: 400;
      font-size: 20px;
      color: #000;
      margin: 0 0 30px;
      line-height: 24px;
    }
  }
  .btn-sm-landing {
    position: absolute;
    bottom: 55px;
    width: 235px;
    left: calc(50% - 117px);
  }
}

#eosc-navy {
  padding: 70px 0 35px;
  background: #000745;
  color: #fff;

  .eosc-logo-navy {
    width: 210px;
    height: auto;
  }

  h4 {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    border: none;
    color: #fff;
    padding: 0;
    margin: 10px 0 0;
  }

  .eosc-info {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;

    .eosc-info-icon {
      margin-right: 20px;
      transition: all 0.5s ease;
    }

    &:hover {
      .eosc-info-icon {
        margin-top: 5px;
      }
    }

    .eosc-info-texts {
      .eosc-info-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        text-decoration: underline;
        margin-bottom: 19px;
        color: #fff;
      }

      .eosc-info-subtitle {
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        color: #fff;
      }
    }
  }
}

#up-to-date {
  position: relative;
  padding: 100px 0 60px;
  background: linear-gradient(180deg, rgba(237, 243, 250, 0) 0%, #edf3fa 100%);

  h3 {
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #333;
    margin-bottom: 60px;
  }

  .cards-wrapper {
    padding: 0 300px;
    display: flex;
    margin-bottom: 25px;
    justify-content: space-between;

    .card {
      background: #fff;
      box-shadow: 14px 8px 16px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      width: 225px;
      text-align: center;
      height: 210px;
      padding-bottom: 20px;
      display: flex;
      justify-content: flex-end;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #000;
      transition: all 0.5s ease;

      &:hover {
        box-shadow: 17px 11px 19px rgba(0, 0, 0, 0.2);
        padding-bottom: 30px;
      }

      img {
        width: auto;
        height: 88px;
        margin: 0 auto 20px;
      }

      &.natural-sciences {
        img {
          margin-bottom: 35px;
        }
      }
    }
  }
}

.eosc-header-row {
  .row {
    background: #fff;
  }
}

.fixed-size {
  display: block;
  width: 600px;
  height: 285px;
}

.content-header {
  padding-top: 50px;
}

.dashboard-carousel {
  .container {
    max-width: 100%;
  }

  .content-header {
    padding-top: 35px;
  }

  .fixed-size {
    display: block;
    width: 455px;
    height: 200px;

    .carousel-image {
      padding-top: 15px !important;

      img {
        width: 100%;
        height: auto;
      }
    }
    .carousel-text {
      padding: 15px 15px 0 0 !important;

      .theme-community {
        font-size: 12px;
        line-height: 15px;
      }

      .text-community {
        font-size: 9px;
        line-height: 14px;
      }
    }
  }
}

.recommendations-widget {
  padding: 25px;

  h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    margin: 0 0 20px;
  }
}

.recommendation {
  padding: 18px 24px;
  margin: 24px 0px;
  border: 1px solid #d9dee2;
  border-radius: 8px;

  .description {
    font-size: 14px;
    line-height: 24px;
    color: #4a4a4a;
    margin: 0 0 5px;
  }
}

.url-title {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  letter-spacing: -0.02em !important;
  text-decoration: underline;
  color: #000;
  margin: 20px 0 8px;

  a {
    color: #000;
    overflow-wrap: break-word;
    font-weight: 500 !important;

    &:hover {
      color: #3d4db6;
    }
  }
}

.main-tag {
  background: #edf4ff;
  border-radius: 24px;
  padding: 5px 15px;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #2255a4;
  margin-right: 20px;

  img {
    margin-top: -2px;
    margin-right: 6px;
  }

  &.required {
    background: rgba(242, 153, 74, 0.2);
    color: #f97500;
  }

  &.access {
    background: #f3ffed;
    color: #27ae60;
  }
}

.tag-row {
  font-size: 12px;
  line-height: 22px;
  opacity: 0.65;
}

.usage {
  .statistic {
    &:last-child {
      width: 100%;
      float: left;
      display: inline-block;
      margin: 9px 0 12px 0 !important;
      line-height: 20px;
    }
  }
}

.tertiary-tags {
  margin-top: 20px;
}

.eosc-common.footer {
  z-index: 100;
  position: relative;
}

.widget-header-theme:hover {
  color: #3d4db6;
}

.join-box {
  border: 1px solid #edf4ff;
  border-radius: 8px;
  margin-top: 16px;
  padding: 15px;
}

.empty-placeholder {
  margin: auto;
  max-width: 500px;
  text-align: center;
  margin-bottom: 120px;

  .placeholder-image {
    margin: 0 auto 25px;
    width: 100%;
    text-align: center;

    img {
      width: 275px;
      height: auto;
    }
  }

  h2 {
    text-align: center;
    font-size: 22px;
    line-height: 32px;
    margin: 0 0 20px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  .btn {
    font-size: 16px;
    padding: 7px 15px;
  }
}

@media only screen and (max-width: 1400px) {
  #up-to-date .cards-wrapper {
    padding: 0 200px;
  }
  .types {
    gap: 4px;
  }

  .recommendations-widget {
    padding: 18px;
  }

  .type {
    padding: 2px 4px;
    font-size: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .paddings {
    padding: 10px;
  }

  .recommendations-widget {
    padding: 10px;
  }

  .types {
    gap: 5px;
  }

  .type {
    padding: 2px 4px;
    font-size: 10px;
  }

  #topmap {
    min-height: auto;
    background: url('assets/stars.svg') 130% 20%,
      linear-gradient(180deg, #edf3fa 0%, rgba(255, 255, 255, 1) 100%);
    background-repeat: no-repeat;
    background-size: contain;

    .stars-bg {
      background: none;
      height: 445px;
    }

    .logo-eosc {
      margin-top: 60px;
    }

    .intro {
      h1 {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 15px;
      }

      .description {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 35px;
      }
    }

    .features-box {
      padding: 15px 15px;
      width: 100%;

      .feature-line {
        font-size: 16px;
      }
    }
  }

  #manage {
    padding: 35px 0 50px;

    .image-placeholder {
      min-height: 250px;
      position: relative;
      margin-bottom: 30px;
    }

    h2 {
      margin-top: 0px;
      font-size: 27px;
    }
  }

  #community {
    padding: 50px 0 30px;

    .sub-title {
      font-size: 20px;
      line-height: 27px;
      max-width: 100%;
    }

    .carousel-text {
      padding: 10px 0 0 0 !important;

      .theme-community {
        font-size: 12px;
        line-height: 16px;
      }

      .text-community {
        font-size: 11px;
        line-height: 16px;
      }
    }

    h2 {
      font-size: 30px;
      line-height: 30px;
    }
  }

  ul.check-list li {
    font-size: 18px;
    line-height: 24px;
    margin: 12px 0 7px;
  }

  #promo {
    min-height: 250px;
  }

  .fixed-size {
    width: 300px !important;
  }

  #resources {
    padding: 50px 0 30px;

    h3 {
      font-size: 35px;
      line-height: 37px;
    }

    .pre-title {
      font-size: 15px;
      line-height: 20px;
    }

    form {
      margin-top: 40px;
    }

    .col-sm {
      flex: unset;
    }

    .right-column {
      p {
        font-size: 18px;
        line-height: 30px;
        padding-top: 20px;
      }
    }
  }

  #join {
    padding: 70px 0 60px;

    h3 {
      font-size: 35px;
      line-height: 37px;
      margin-bottom: 20px;
    }

    .tile {
      width: 100%;
      margin-bottom: 30px;

      .title {
        bottom: 45px;
        font-size: 28px;
      }
    }
  }

  #eosc-navy {
    padding: 45px 15px 20px;

    .eosc-logo-navy {
      width: 95%;
      height: auto;
    }

    .mb-5 {
      text-align: center;
      margin-bottom: 15px;
    }

    h4 {
      font-size: 16px;
      line-height: 22px;
      margin: 0;
    }
    .eosc-info {
      .eosc-info-texts {
        .eosc-info-title {
          margin-bottom: 5px;
          font-size: 16px;
          line-height: 21px;
        }
      }
    }

    .eosc-info-icon {
      img {
        width: 45px;
      }
    }
  }

  #up-to-date {
    padding: 40px 0 40px;

    h3 {
      font-size: 32px;
      line-height: 37px;
      margin-bottom: 30px;
    }
    .cards-wrapper {
      padding: 0 15px;
      margin: 0;

      .card {
        width: 30%;
        text-align: center;
        margin-bottom: 15px;
        padding-bottom: 0;
        justify-content: center;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .user-profile-padding {
    padding: 20px 0 0;
    height: auto;
  }
  .nav-sel[_ngcontent-asq-c94] {
    left: 11px;
  }

  .url-title {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .main-tag {
    padding: 5px 11px;
    font-size: 12px;
    line-height: 20px;
  }

  .recommendation {
    .description {
      font-size: 12px;
      line-height: 21px;
    }
  }

  #topmap {
    background: url('assets/stars.svg') 225px -140px,
      linear-gradient(180deg, #edf3fa 0%, rgba(255, 255, 255, 1) 100%);
    background-repeat: no-repeat;
    min-height: 360px;

    .stars-bg {
      background: none;
      height: auto;
    }

    .logo-eosc {
      margin-top: 35px;
    }

    .intro {
      h1 {
        font-size: 23px;
        line-height: 30px;
        margin-bottom: 12px;
      }

      .description {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px;
      }
    }

    .features-box {
      margin-top: 40px;
      width: auto;
      padding: 10px 15px;

      .feature-line {
        font-size: 15px;
      }
    }
  }

  #manage {
    padding: 25px 0 10px;

    .image-placeholder {
      max-width: 100%;
      min-height: 220px;
    }

    h2 {
      margin-top: 0;
      font-size: 23px;
    }
  }

  #community {
    padding: 50px 0 30px;

    .sub-title {
      font-size: 14px;
      line-height: 23px;
    }

    h2 {
      font-size: 24px;
      line-height: 30px;
    }
  }

  ul.check-list li {
    font-size: 17px;
    line-height: 24px;
    margin: 12px 0 7px;
  }

  #promo {
    min-height: 200px;
  }

  .fixed-size {
    width: 270px !important;
  }

  #resources {
    padding: 50px 0 30px;

    h3 {
      font-size: 24px;
      line-height: 31px;
    }

    .pre-title {
      font-size: 13px;
      line-height: 20px;
    }

    form {
      margin-top: 20px;
    }

    .right-column {
      p {
        font-size: 17px;
        line-height: 27px;
        padding-top: 20px;
      }
    }
  }

  #join {
    padding: 60px 0 5px;

    h3 {
      font-size: 24px;
      line-height: 31px;
      margin-bottom: 20px;
    }

    .tile {
      width: 100%;
      min-height: 570px;
      margin-bottom: 30px;
      padding: 15px 15px;

      .title {
        font-size: 20px;
        margin: 15px 0 10px;
      }
      .desc {
        font-size: 15px;
        margin: 0 0 25px;
        line-height: 21px;
      }
    }
  }

  .btn-secondary {
    font-size: 14px;
  }

  #up-to-date {
    padding: 40px 0 40px;

    h3 {
      font-size: 24px;
      line-height: 31px;
      margin-bottom: 30px;
    }
    .cards-wrapper {
      padding: 0 15px;
      display: inline-block;
      margin: 0;

      .card {
        width: 100%;
        text-align: center;
        height: 195px;
        margin-bottom: 15px;
        padding-bottom: 0;
        justify-content: center;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}
